// Description: Console log for Damselfly Creative
/*
import figlet from "figlet";
import standard from "figlet/importable-fonts/Standard.js";
figlet.parseFont("Standard", standard);
figlet.text("Damselfly\nCreative", function (err, data){
 console.log(data, '\nSITE BY: https://damselflycreative.com')
});
*/

console.log(`  ____                           _  __ _       
 |  _ \\  __ _ _ __ ___  ___  ___| |/ _| |_   _ 
 | | | |/ _\` | '_ \` _ \\/ __|/ _ \\ | |_| | | | |
 | |_| | (_| | | | | | \\__ \\  __/ |  _| | |_| |
 |____/ \\__,_|_| |_| |_|___/\\___|_|_| |_|\\__, |
  / ___|_ __ ___  __ _| |_(_)_   _____   |___/ 
 | |   | '__/ _ \\/ _\` | __| \\ \\ / / _ \\        
 | |___| | |  __/ (_| | |_| |\\ V /  __/        
  \\____|_|  \\___|\\__,_|\\__|_| \\_/ \\___|        
                                                
SITE BY: https://damselflycreative.com`);